.section-navigation {
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
  align-self: center;

  transition: opacity $transition--easing $transition--rate;

  &__button {
    align-items: center;
    background-color: $color--white;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: center;
    transition: opacity $transition--easing $transition--rate;
    width: 48px;
    i {
      margin-top: -0.3125rem;
      transform: rotate(90deg);
      &::before {
        color: $color--green-dark;
        font-size: 24px;
        line-height: 1em;
        text-align: center;
        vertical-align: middle;
      }
    }
    &:hover,
    &:focus,
    &:active {
      background-color: $color--green-dark;
      opacity: 1;
      i {
        &::before {
          color: $color--white;
        }
      }
    }
  }
}
