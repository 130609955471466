.copyright {
  color: $color--white;
  line-height: 2em;
  padding: $padding--small $padding--small $padding--medium;
  text-align: center;
  @include bp('m-md') {
    margin: $padding--medium $padding--small;
  }

  a {
    color: $color--white;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.fp-watermark {
  opacity: 0;
}