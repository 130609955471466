// Widths
$width--micro: 310px; //19.375rem;
$width--small: 400px; //25rem;
$width--medium: 550px; //34.375rem;
$width--large: 680px; //42.5rem;
$width--large: 820px; //51.25rem;
$width--xlarge: 1360px; //85rem;
$width--xxlarge: 1640px; //102.5rem;

$g_site-width-max: 1440px;

// Viewport Breakpoints
// These breakpoints were chosen after carefully comparing the following two sets
// of data:
// https://www.screensiz.es/
// http://devfacts.com/media-queries-breakpoints-2020/

$breakpoints: (
  'm-xs': (
    min-width: 320px,
  ),
  'm-xs-360': (
    min-width: 360px,
  ),
  'm-xs-375': (
    min-width: 375px,
  ),
  'designer': (
    min-width: 390px,
  ),
  'm-sm': (
    min-width: 568px,
  ),
  'm-md': (
    min-width: 768px,
  ),
  'm-lg': (
    min-width: 1024px,
  ),
  'm-xl': (
    min-width: 1366px,
  ),
  'd-sm': (
    min-width: 1680px,
  ),
  'd-md': (
    min-width: 1920px,
  ),
  'd-lg': (
    min-width: 2400px,
  ),
  '-m-xs': (
    max-width: 319px,
  ),
  '-designer': (
    max-width: 389px,
  ),
  '-m-sm': (
    max-width: 567px,
  ),
  '-m-md': (
    max-width: 767px,
  ),
  '-m-lg': (
    max-width: 1023px,
  ),
  '-m-xl': (
    max-width: 1365px,
  ),
  '-d-sm': (
    max-width: 1679px,
  ),
  '-d-md': (
    max-width: 1919px,
  ),
  '-d-lg': (
    max-width: 2399px,
  ),
);

// Colors - Monochromatic
$color--black: #000;
$color--grey-dark: #2b2b2b;
$color--grey-medium: #949494;
$color--grey-light: #f0f0f0;
$color--white: #fff;

// Colors - Green
$color--green-dark: #1e855a;
$color--green-light: #26a671;

// Colors - Yellow
$color--green-yellow-dark: #8fc640;
$color--green-yellow-light: #feff80;

// Colors - Gradients
$color--gradient-green-yellow: linear-gradient(180deg, $color--green-yellow-dark 0%, $color--green-yellow-light 100%);
$color--gradient-green: linear-gradient(150deg, #16724b 0%, #0d5d3c 100%);
$color--gradient-transparent: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 24.68%, rgba(255, 255, 255, 0) 101.97%);
$color--gradient-white: linear-gradient(150deg, $color--white 0%, $color--grey-light 100%);
$color--gradient-sky: linear-gradient(180deg, #ffffff 0%, #c6fafe 100%);

// Transition Properties
$transition--rate: 250ms;
$transition--easing: ease-in-out;

// Fonts
$font--core: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
$font--monsterrat: 'Monsterrat', $font--core;
$font--geometos: 'Geometos Rounded', $font--core;

// Padding
$padding--small: 20px; //1.25rem;
$padding--medium: 45px; //2.8125rem;
$padding--large: 70px; //4.375rem;

// Global variables
$g_font-size-min: 16px;
$g_font-size-max: 20px;
$g_line-height: 1em;
$g_line-height-lg: 1.2em;
$g_font-weight: 500;
$g_font-colour: #4b4b4b;

$g_header-height: 90px;
$g_logo-size-min: 40px;
$g_logo-size-max: 62px;

$g_footer-height: 114px;
$g_logo-coles-size-min: 96px;
$g_logo-coles-size-max: 114px;

$g_content-width: 85%;
$g_content-width-max: 640px;

$g_blip-bg-size-min: 50px;
$g_blip-bg-size-max: 75px;
$g_blip-icon-size-min: $g_blip-bg-size-min + 25px;
$g_blip-icon-size-max: $g_blip-bg-size-max + 25px;

$g_site-font-size-min: 16px;
$g_site-font-size-max: 16px;

$g_heading-colour: $color--green-dark;
$g_heading-weight: 400;
$g_h1-size-desktop: 64px;
$g_h1-size-mobile: 37px;
$g_h2-size-desktop: 34px;
$g_h2-size-mobile: 24px;
$g_h3-size-desktop: 30px;
$g_h3-size-mobile: 30px;
$g_h4-size-desktop: 20px;
$g_h4-size-mobile: 20px;
$g_h5-size-desktop: 18px;
$g_h5-size-mobile: 16px;
$g_h6-size-desktop: 16px;
$g_h6-size-mobile: 12px;

$g_h1-sm-size-desktop: 36px;
$g_h1-sm-size-mobile: 24px;

$g_h2-lg-size-desktop: 36px;
$g_h2-lg-size-mobile: 26px;
