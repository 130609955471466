@use 'sass:math';

.navigation {
  $padding-seed: 50px; //3.125rem;
  // Developer Note: Remove the double backgrounds to stop the gradient appearing
  // over the menu.
  background: $color--green-dark;

  bottom: 0;
  display: block;
  max-width: $width--micro;
  position: fixed;
  // 'Right Side of Viewport' - ('Viewport Size' - 'Space For Logo')
  // right: calc(0rem - (100vw - 80px));
  right: -100vw;

  top: 0;
  transition: right $transition--rate ease-in-out;
  width: 300px;

  @include bp('m-lg') {
    align-items: center;
    background: initial;
    bottom: initial;
    display: flex;
    justify-content: flex-end;
    max-width: initial;
    padding: initial;
    position: initial;
    right: initial;
    top: initial;
    transition: initial;
    width: initial;
  }

  // Accessibility
  @media (prefers-reduced-motion) {
    transition: none;
  }

  &--open {
    display: block;
    right: 0rem;
    @include bp('m-lg') {
      right: auto;
    }
  }
  &__control {
    height: 3rem;
    margin: 0;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 48px;
    z-index: 1;
    @include bp('m-lg') {
      display: none !important;
    }
  }
  &__list {
    margin-top: 100px;
    @include bp('m-lg') {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-top: 0;
    }
    &-item {
      text-align: left;
      @include bp('m-lg') {
        // margin: 0 25px 0 0;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  &__link {
    color: $color--white;
    display: block;
    font-family: $font--monsterrat;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 15px 0;
    // padding: 6px;
    // margin: 0 0 6px 0;
    padding: 15px;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    text-underline-offset: 4px;

    @include bp('designer') {
    }
    @include bp('m-lg') {
      color: $color--green-dark;
      margin: 0;
      text-align: center;
      .nav-white & {
        color: $color--white;
      }
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .button {
    font-size: 14px;
    font-weight: 600;
    line-height: 1em;
    margin: 10px;
    @include bp('m-lg') {
      margin: 0 10px;
    }
  }
  &.nav-white {
    .button {
      border-color: $color--white !important;
      color: $color--white !important;

      &:hover,
      &:active,
      &:focus {
        background-color: $color--white !important;
        border-color: $color--white !important;
        color: $color--green-dark !important;
      }
    }
  }
}
