h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color--green-dark;
  font-family: $font--geometos;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 20px; //0.5em
  padding: 0;
  line-height: 1em;

  &.variant-white {
    color: $color--white;
  }

  &.heading-as-body-copy {
    color: $color--grey-dark;
    font-family: $font--monsterrat;
    @include fluid-type($g_font-size-min, $g_font-size-max - 2);
    text-transform: initial;
  }
}

h1 {
  @include fluid-type($g_h1-size-mobile, $g_h1-size-desktop);

  &.h-sm {
    @include fluid-type($g_h1-sm-size-mobile, $g_h1-sm-size-desktop);
  }
}
h2 {
  @include fluid-type($g_h2-size-mobile, $g_h2-size-desktop);
  &.h-lg {
    @include fluid-type($g_h2-lg-size-mobile, $g_h2-lg-size-desktop);
    line-height: 1.2em;
  }
}
h3 {
  @include fluid-type($g_h3-size-mobile, $g_h3-size-desktop);
}
h4 {
  @include fluid-type($g_h4-size-mobile, $g_h4-size-desktop);
}
h5 {
  @include fluid-type($g_h5-size-mobile, $g_h5-size-desktop);
}
h6 {
  @include fluid-type($g_h6-size-mobile, $g_h6-size-desktop);
}
