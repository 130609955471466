.counter {
  font-family: $font--geometos;
  margin: 0;
  &__value {
    display: block;
    font-size: 1.7rem;
    line-height: 2.3rem;
    margin: 0.5rem 0 0 0;
    // span {
    //   font-size: 1rem;
    //   @include bp('m-lg') {
    //     font-size: 1.625rem;
    //   }
    // }
    @include bp('m-lg') {
      font-size: 4rem;
      line-height: 5rem;
    }
  }
  &__unit {
    display: block;
    font-size: 1rem;
    @include bp('m-lg') {
      font-size: 1.625rem;
    }
  }
  button {
    margin-bottom: 0;
  }
}
