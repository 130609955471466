.content {
  align-items: center;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 25px;
  text-align: center;
  width: 100%;
  z-index: 10;
  @include bp('m-md') {
    padding: 40px 30px;
  }

  &--white {
    background-color: rgba(255, 255, 255, 0.8);
  }
  &--brown {
    background-color: rgba(61, 60, 52, 0.8);
  }
  &--black {
    background-color: rgba(30, 33, 32, 0.8);
  }
  &--transparent {
    background-color: rgba(255, 255, 255, 0);
  }

  .heading-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    margin: 0 0 20px;
    @include bp('m-md') {
      align-items: flex-start;
      flex-wrap: nowrap;
      gap: 20px;
      width: 100%;
      width: 100%;
    }

    h1 {
      margin: 0;
    }

    &-text {
    }
    &-date {
      max-width: 124px;
      @include bp('m-sm') {
        max-width: 130px;
      }
      @include bp('m-lg') {
        max-width: 168px;
      }
    }
  }

  .text-container {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    padding: 20px;
  }

  .logo-container {
    align-items: center;
    display: flex;
    gap: 30px;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;
    @include bp('m-md') {
      gap: 50px;
      // margin-bottom: 15%;
    }

    .logo {
      flex-grow: 1;
      width: 100%;

      &-cfc {
        max-width: 90px;
        @include bp('m-md') {
          max-width: 154px;
        }
      }
      &-coles {
        max-width: 100px;
        @include bp('m-md') {
          max-width: 181px;
        }
      }
    }

    img.variant-white {
      filter: brightness(0) invert(1);
    }
  }
}

.section--donate-coles {
  .content {
    p.variant-white {
      font-size: 12px;
      @include bp('m-md') {
        font-size: 18px;
      }
    }
  }
}
