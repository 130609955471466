ol {
  list-style: decimal;
  text-align: left;
  li {
    padding-left: 10px;
    margin: 10px 45px 10px 45px;
    line-height: 1.5;
    @include bp('m-lg') {
      margin: 10px 60px 10px 60px;
    }
  }
}
