.header {
  // border: 1px solid red;
  background: $color--gradient-transparent;
  display: flex;
  left: 0;
  min-height: $g_header-height; // 5rem;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 1;

  &.nav-white {
    background: none;
    .icon--menu {
      &::before {
        color: $color--white;
      }
    }
    .header__logo {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  .icon--cross,
  .icon--menu {
    transition: opacity $transition--easing $transition--rate;
    position: absolute;
    left: 20%;
    top: 25%;
  }

  .navigation__control {
    border: none;
  }
  .icon--menu {
    &::before {
      color: $color--green-dark;
    }
  }

  .icon--cross {
    left: 30%;
  }
  &__bounding-container {
    // border: 1px solid green;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 0 0 15px;
    position: relative;
    width: 100%;
    max-width: $g_site-width-max;
    text-align: center;
    @include bp('m-lg') {
    }
  }
  &__call-to-action {
    margin: 0 auto;
    font-size: 0.625rem;
    @include bp('m-xs') {
      font-size: 0.75rem;
    }
    @include bp('m-sm') {
      font-size: 0.875rem;
    }
  }
  &__logo {
    display: block;
    margin: 0;
    padding: 0;

    height: $g_logo-size-min;
    width: $g_logo-size-min;

    transition: transform $transition--rate ease-in-out;
    @include bp('m-md') {
      height: $g_logo-size-max;
      width: $g_logo-size-max;
      transform: scale(0.75);
    }
    @include bp('m-lg') {
      transform: initial;
    }
  }
}
