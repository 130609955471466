@use 'sass:math';

.illustration {
  background-size: cover;
  position: absolute;
  &--girl {
    background-image: url('../images/iconography/girl.png');
    bottom: 13.125rem;
    height: 37.9375rem;
    left: 9.375rem;
    width: 26.9375rem;
    &-and-dog {
      background-image: url('../images/iconography/girl-and-dog.png');
      // bottom: 3.125rem;
      bottom: -15%;
      left: 50%;
      height: math.div(716px, 3);
      margin-left: -4.375rem;
      width: math.div(420px, 3);
      opacity: 0.1;
      @include bp('m-sm') {
        // height: math.div(716px, 2);
        // margin-left: -6.5625rem;
        // width: math.div(420px, 2);
      }
      @include bp('m-md') {
        height: 44.75rem;
        width: 26.25rem;
        left: 10%;
        bottom: 0;
      }
    }
  }
  &--bin-chicken {
    &-1 {
      background-image: url('../images/iconography/bin-chicken-1.png');
      bottom: 25.625rem;
      height: 20.25rem;
      left: 81.875rem;
      width: 16.4375rem;
    }
    &-2 {
      background-image: url('../images/iconography/bin-chicken-2.png');
      bottom: 24.0625rem;
      height: 9.3125rem;
      left: 18.4375rem;
      width: 14.4375rem;
    }
  }
  &--bottle {
    background-image: url('../images/iconography/bottle.png');
    bottom: 24.0625rem;
    height: 4.6875rem;
    left: 18.4375rem;
    width: 9.0625rem;
    opacity: 1;
  }
}
