.footer {
  &-static {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    max-height: $g_footer-height;

    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;

    width: 100vw;
    z-index: 100;

    &.nav-white {
      display: none;
    }
  }

  &-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 15px;
    width: 100%;
    max-width: 1440px;
  }

  &__logo-coles {
    grid-column: 1;
    grid-row: 1;
    justify-self: flex-end;
    width: 96px;
    @include bp('m-lg') {
      width: 114px;
    }
  }
}
