.grid-container {
  align-items: center;
  background-repeat: no-repeat !important;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
}
.fp-overflow {
  height: 100% !important;
}

.section {
  // background-position: bottom !important;
  background-repeat: no-repeat !important;
  // background-size: cover !important;
  //

  &--pledge {
    background: url('../images/backgrounds/bg-4.jpg');
    background-position: center;
    background-size: cover;
  }

  &--home-redeemed,
  &--home-reinvented,
  &--home-dont-feed,
  &--donate-chosen-support,
  &--donate-empties-day {
    background: url('../images/backgrounds/texture.png'), $color--gradient-sky;
    background-position: bottom, bottom;

    background-size: cover, cover;
  }

  &__animation-container {
    display: grid;
    grid-column: 1;
    grid-row: 1;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 100%;
    overflow: hidden;
  }
  &__content-container {
    align-items: flex-start;
    display: flex;
    grid-column: 1;
    grid-row: 1;
    height: 100%;
    justify-content: flex-start;
    padding-right: 5%; //$padding--small;
    padding-top: calc(#{$g_header-height} + 1%); //$padding--small;
    width: 100%;
    @include bp('designer') {
      justify-content: center;
      padding-right: 0;
    }

    .content {
      max-width: $g_content-width-max;
      width: $g_content-width;
      // width: 85%;
      // max-width: 640px;

      #homePage & {
        // max-width: 660px;
        max-width: 760px;
      }
      &--wide {
        max-width: 1200px !important;
      }
    }
  }
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background-color: $color--white;
}

.fp-nav--hidden {
  opacity: 0.25 !important;
}

.fp-slides {
  width: 100%;
}

#fp-nav.fp-right {
  right: 1px;
}
