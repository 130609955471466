a.button {
  display: inline-block;
}
.button {
  align-items: center;
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  line-height: 1.4375rem;
  margin: 16px 10px;
  padding: 10px 20px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color $transition--rate $transition--easing, border-color $transition--rate $transition--easing, color $transition--rate $transition--easing, opacity $transition--rate $transition--easing;
  user-select: none;
  // @include bp('designer') {
  //   font-size: 1.1875rem;
  //   margin: 0.9375rem;
  // }
  i {
    display: inline-block;
    text-decoration: none;
    &:before {
      transition: color $transition--rate ease-in-out;
    }
  }
  @include bp('m-lg') {
    line-height: 1.75rem;
  }
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
  &--hidden-mobile {
    display: none !important;
    @include bp('m-lg') {
      display: inline-flex;
    }
  }
  &--hidden-desktop {
    @include bp('m-lg') {
      display: none !important;
    }
  }
  &--variant {
    // White on Green
    &-1 {
      background-color: $color--green-dark;
      // border-color: $color--white !important;
      border: none;
      color: $color--white;
      min-width: 166px;
      &:hover,
      &:active,
      &:focus {
        background-color: $color--white;
        border-color: $color--green-dark;
        color: $color--green-dark;
        i:before {
          color: $color--green-dark;
        }
      }
      &:disabled {
        opacity: 0.5;
      }
    }
    // Green on White
    &-2 {
      background-color: $color--white;
      border-color: $color--white;
      color: $color--green-dark;
      i:before {
        color: $color--green-dark;
      }
      &:hover,
      &:active,
      &:focus {
        background-color: $color--green-dark;
        border-color: $color--white;
        color: $color--white;
        i:before {
          color: $color--white;
        }
      }
      &:disabled {
        opacity: 0.5;
      }
    }
    // White on Transparent
    &-3 {
      background-color: transparent;
      border-color: transparent;
      color: $color--white;
      &:hover,
      &:active,
      &:focus {
        border-color: $color--white;
        border-width: 0.125rem;
      }
    }
    // Green on Transparent
    &-4 {
      background-color: transparent;
      // border-color: transparent;
      border-color: $color--white;
      // color: $color--green-dark;
      color: $color--white;
      &:hover,
      &:active,
      &:focus {
        background-color: $color--green-dark;
        border-color: $color--green-dark;
        border-width: 0.125rem;
        color: $color--white;
        i:before {
          color: $color--green-dark;
        }
      }

      @include bp('m-lg') {
        color: $color--green-dark;
        border-color: $color--green-dark;

        .nav-white & {
          color: $color--white;
          border-color: $color--white;
        }
      }
    }
    // White on Transparent (Circular)
    &-5 {
      align-items: initial;
      background-color: transparent;
      border: none;
      border: 0.1875rem solid $color--white;
      border-radius: 31.25rem;
      color: $color--green-dark;
      // Resetting the border is important when using a `button` element.
      display: block;
      justify-content: initial;
      line-height: initial;
      margin: 1.875rem auto 0 auto;
      padding: 1.25rem 1rem 1.25rem 1.5rem;
      @include bp('m-lg') {
        margin: 3.125rem auto 0 auto;
        padding: 1.75rem 1.375rem 1.75rem 2.05rem;
      }
      i {
        font-size: 2.1875rem;
        @include bp('m-lg') {
          font-size: 3.125rem;
        }
      }
      &:hover,
      &:active,
      &:focus {
        background-color: $color--white;
        color: $color--green-dark;
        i:before {
          color: $color--green-dark;
        }
      }
    }

    &-6 {
      background-color: transparent;
      border-color: $color--white;
      color: $color--white;
      &:hover,
      &:active,
      &:focus {
        background-color: $color--white;
        color: $color--green-dark;
        i:before {
          color: $color--green-dark;
        }
      }
    }
  }
  // Developer Note: A span within a button will only be used when icon-only
  // buttons are used.
  span {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 0.0625rem;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 0.0625rem;
  }

  &-container {
    margin-bottom: 20px;
  }
}
