.overlay {
  background-color: $color--green-dark;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color 250ms ease-in-out 1000ms;
  z-index: 1000;
  &--loaded {
    pointer-events: none;
    background-color: transparent;
  }
}
