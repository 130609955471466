// Geometos Rounded
@font-face {
  font-family: 'Geometos Rounded';
  src: url('../fonts/geometos/Geometos-Rounded.eot');
  src: url('../fonts/geometos/Geometos-Rounded.eot?#iefix') format('embedded-opentype'),
    url('../fonts/geometos/Geometos-Rounded.woff2') format('woff2'),
    url('../fonts/geometos/Geometos-Rounded.woff') format('woff'),
    url('../fonts/geometos/Geometos-Rounded.svg#Geometos Rounded') format('svg');
  font-display: swap;
  font-style: normal;
}

// Monsterrat
@font-face {
  font-family: 'Monsterrat';
  src: url('../fonts/montserrat/montserrat-thin-webfont.woff2') format('woff2'),
    url('../fonts/montserrat/montserrat-thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Monsterrat';
  src: url('../fonts/montserrat/montserrat-extralight-webfont.woff2') format('woff2'),
    url('../fonts/montserrat/montserrat-extralight-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Monsterrat';
  src: url('../fonts/montserrat/montserrat-light-webfont.woff2') format('woff2'),
    url('../fonts/montserrat/montserrat-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Monsterrat';
  src: url('../fonts/montserrat/montserrat-regular-webfont.woff2') format('woff2'),
    url('../fonts/montserrat/montserrat-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Monsterrat';
  src: url('../fonts/montserrat/montserrat-medium-webfont.woff2') format('woff2'),
    url('../fonts/montserrat/montserrat-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Monsterrat';
  src: url('../fonts/montserrat/montserrat-semibold-webfont.woff2') format('woff2'),
    url('../fonts/montserrat/montserrat-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Monsterrat';
  src: url('../fonts/montserrat/montserrat-bold-webfont.woff2') format('woff2'),
    url('../fonts/montserrat/montserrat-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Monsterrat';
  src: url('../fonts/montserrat/montserrat-extrabold-webfont.woff2') format('woff2'),
    url('../fonts/montserrat/montserrat-extrabold-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Monsterrat';
  src: url('../fonts/montserrat/montserrat-black-webfont.woff2') format('woff2'),
    url('../fonts/montserrat/montserrat-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

// Icomoon
@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?m3h2si') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?m3h2si') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?m3h2si##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
