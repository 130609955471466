.decision {
  background: linear-gradient(180deg, #0e7b55 0%, #a1de52 100%) !important;
  display: flex;
  flex-direction: column;
  grid-column: 1;
  grid-row: 1;

  height: 100%;
  justify-content: center;
  z-index: 2;

  &__content {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    flex-direction: column;
    @include bp('m-md') {
      flex-direction: row;
    }
  }
  &__choice {
    background-color: $color--white;
    // border: 0.375rem solid transparent;
    border-radius: 25px;
    cursor: pointer;
    display: block;
    margin: 20px 0.3125rem;
    overflow: hidden;
    padding: 7.1875rem 0 0 0;
    position: relative;
    // transition: border-color $transition--easing $transition--rate;
    width: 9.75rem;
    height: 9.75rem;
    @include bp('m-sm') {
      height: unset;
      width: 12.5rem;
    }
    @include bp('m-md') {
      width: 15.625rem;
    }
    @include bp('m-lg') {
      width: 18.75rem;
    }
    @include bp('m-xl') {
      width: 25rem;
    }

    &:hover,
    &:active,
    &:focus {
      // border-color: $color--green-light; // $color--green-dark;
      span {
        color: $color--green-dark;
      }
    }
    span {
      color: #0e7b55;
      display: block;
      font-family: $font--geometos;
      font-size: 1rem;
      font-weight: 500;
      left: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      top: 1rem;
      transition: color $transition--easing $transition--rate;
      @include bp('m-md') {
        font-size: 1.625rem;
        line-height: 1.875rem;
        top: 2.8125rem;
      }
    }
    video {
      display: block;
      margin: 0;
      padding: 0;
      pointer-events: none;
      width: 100%;
    }
    &--1 {
      padding: 4.375rem 0 0 0;
      @include bp('m-md') {
        padding: 4.4375rem 0 0 0;
      }
    }
    &--2 {
      padding: 4.375rem 0 0 0;
      @include bp('m-md') {
        padding: 7.1875rem 1.875rem 0 1.875rem;
      }
    }
  }
  &__operand {
    // display: none;
    color: $color--white;
    display: block;
    font-family: $font--geometos;
    padding: 0 2.5rem;
    user-select: none;
    font-size: 30px;
    @include bp('m-lg') {
      font-size: 80px;
    }
  }
}
