@use 'sass:math';
/// Mixin to manage responsive breakpoints
/// @author David Woodward
/// @param {String} $breakpoint - Breakpoint from within the variable set.
/// @require $breakpoints
@mixin bp($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Print a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "`#{$breakpoint}` breakpoint was not found."
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin fluid-type($min-font-size: $g_site-font-size-min, $max-font-size: $g_site-font-size-max, $lower-range: 320px, $upper-range: 1366px) {
  font-size: calc(#{$min-font-size} + #{(math.div($max-font-size, $max-font-size * 0 + 1) - math.div($min-font-size, $min-font-size * 0 + 1))} * ((100vw - #{$lower-range}) / #{(math.div($upper-range, $upper-range * 0 + 1) - math.div($lower-range, $lower-range * 0 + 1))}));

  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range) {
    font-size: $max-font-size;
  }
}
