/** Main Generic Loader */

// Generic – reset and/or normalize styles, box-sizing definition, etc. This is the first layer which generates actual CSS.

@import '../../../../node_modules/reset-css/reset';
@import '../../../../node_modules/normalize.css/normalize';
@import '../../../../node_modules/fullpage.js/dist/fullpage';

* {
  box-sizing: border-box;
  & > * {
    box-sizing: border-box;
  }
}
