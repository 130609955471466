i {
  font-variant: normal;
  speak: never;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: icomoon !important;
}

.icon--within-button-left {
  margin: 0 .625rem 0 0;
}

.icon--within-button-right {
  margin: 0 0 0 .625rem;
}

.icon--hidden {
  opacity: 0;
  pointer-events: none;
}

.icon--speaker:before {
  content: "";
  color: #fff;
}

.icon--chevron:before {
  content: "";
  color: #fff;
}

.icon--cross:before {
  content: "";
  color: #fff;
}

.icon--location:before {
  content: "";
  color: #fff;
}

.icon--logo-facebook:before {
  content: "";
  color: #fff;
}

.icon--logo-instagram:before {
  content: "";
  color: #fff;
}

.icon--logo-twitter:before {
  content: "";
  color: #fff;
}

.icon--menu:before {
  content: "";
  color: #fff;
}

.icon--play:before {
  content: "";
  color: #fff;
}

.icon--repeat:before {
  content: "";
  color: #fff;
}

.icon--tick:before {
  content: "";
  color: #fff;
}

@font-face {
  font-family: Geometos Rounded;
  src: url("Geometos-Rounded.d7577089.eot");
  src: url("Geometos-Rounded.d7577089.eot#iefix") format("embedded-opentype"), url("Geometos-Rounded.28d7272f.woff2") format("woff2"), url("Geometos-Rounded.f3690cd9.woff") format("woff"), url("Geometos-Rounded.66b05e87.svg#Geometos%20Rounded") format("svg");
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Monsterrat;
  src: url("montserrat-thin-webfont.4051ffff.woff2") format("woff2"), url("montserrat-thin-webfont.927d5a72.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Monsterrat;
  src: url("montserrat-extralight-webfont.f6671f31.woff2") format("woff2"), url("montserrat-extralight-webfont.88b99427.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Monsterrat;
  src: url("montserrat-light-webfont.2fc644d4.woff2") format("woff2"), url("montserrat-light-webfont.72c06801.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Monsterrat;
  src: url("montserrat-regular-webfont.428f734c.woff2") format("woff2"), url("montserrat-regular-webfont.bd38773d.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Monsterrat;
  src: url("montserrat-medium-webfont.358640f3.woff2") format("woff2"), url("montserrat-medium-webfont.7384c3dd.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Monsterrat;
  src: url("montserrat-semibold-webfont.1a061a70.woff2") format("woff2"), url("montserrat-semibold-webfont.a2371012.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Monsterrat;
  src: url("montserrat-bold-webfont.18924d1b.woff2") format("woff2"), url("montserrat-bold-webfont.baad2dbe.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Monsterrat;
  src: url("montserrat-extrabold-webfont.1be9985e.woff2") format("woff2"), url("montserrat-extrabold-webfont.88d6e085.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Monsterrat;
  src: url("montserrat-black-webfont.b24fc709.woff2") format("woff2"), url("montserrat-black-webfont.b2c7a617.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: icomoon;
  src: url("icomoon.ed09bac0.ttf") format("truetype"), url("icomoon.5dffe8b7.woff") format("woff"), url("icomoon.44826ec0.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

html.fp-enabled, .fp-enabled body {
  -webkit-tap-highlight-color: #0000;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.fp-section {
  box-sizing: border-box;
  height: 100%;
  display: block;
  position: relative;
}

.fp-slide {
  float: left;
}

.fp-slide, .fp-slidesContainer {
  height: 100%;
  display: block;
}

.fp-slides {
  z-index: 1;
  height: 100%;
  transition: all .3s ease-out;
  position: relative;
  overflow: hidden;
}

.fp-table {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  display: flex;
}

.fp-slidesContainer {
  float: left;
  position: relative;
}

.fp-controlArrow {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  z-index: 4;
  cursor: pointer;
  margin-top: -38px;
  position: absolute;
  top: 50%;
  transform: translate3d(0, 0, 0);
}

.fp-prev {
  left: 15px;
}

.fp-next {
  right: 15px;
}

.fp-arrow {
  border-style: solid;
  width: 0;
  height: 0;
}

.fp-arrow.fp-prev {
  border-width: 38.5px 34px 38.5px 0;
  border-color: #0000 #fff #0000 #0000;
}

.fp-arrow.fp-next {
  border-width: 38.5px 0 38.5px 34px;
  border-color: #0000 #0000 #0000 #fff;
}

.fp-notransition {
  transition: none !important;
}

#fp-nav {
  z-index: 100;
  opacity: 1;
  transform: translateY(-50%);
  position: fixed;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
}

#fp-nav.fp-left {
  left: 17px;
}

.fp-slidesNav {
  z-index: 4;
  opacity: 1;
  position: absolute;
  right: 0;
  transform: translate3d(0, 0, 0);
  margin: 0 auto !important;
  left: 0 !important;
}

.fp-slidesNav.fp-bottom {
  bottom: 17px;
}

.fp-slidesNav.fp-top {
  top: 17px;
}

#fp-nav ul, .fp-slidesNav ul {
  margin: 0;
  padding: 0;
}

#fp-nav ul li, .fp-slidesNav ul li {
  width: 14px;
  height: 13px;
  margin: 7px;
  display: block;
  position: relative;
}

.fp-slidesNav ul li {
  display: inline-block;
}

#fp-nav ul li a, .fp-slidesNav ul li a {
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: block;
  position: relative;
}

#fp-nav ul li a.active span, .fp-slidesNav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li:hover a.active span {
  border-radius: 100%;
  width: 12px;
  height: 12px;
  margin: -6px 0 0 -6px;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
  z-index: 1;
  -o-transition: all .1s ease-in-out;
  background: #333;
  border: 0;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
}

#fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
}

#fp-nav ul li .fp-tooltip {
  color: #fff;
  white-space: nowrap;
  opacity: 0;
  cursor: pointer;
  width: 0;
  max-width: 220px;
  font-family: arial, helvetica, sans-serif;
  font-size: 14px;
  display: block;
  position: absolute;
  top: -2px;
  overflow: hidden;
}

#fp-nav ul li:hover .fp-tooltip, #fp-nav.fp-show-active a.active + .fp-tooltip {
  opacity: 1;
  width: auto;
  transition: opacity .2s ease-in;
}

#fp-nav ul li .fp-tooltip.fp-right {
  right: 20px;
}

#fp-nav ul li .fp-tooltip.fp-left {
  left: 20px;
}

.fp-auto-height.fp-section, .fp-auto-height .fp-slide, .fp-responsive .fp-is-overflow.fp-section {
  height: auto !important;
}

.fp-scrollable.fp-responsive .fp-is-overflow.fp-section, .fp-scrollable .fp-section, .fp-scrollable .fp-slide {
  height: calc(var(--vh, 1vh) * 100);
  height: 100vh;
}

.fp-scrollable.fp-responsive .fp-is-overflow.fp-section:not(.fp-auto-height):not([data-percentage]), .fp-scrollable .fp-section:not(.fp-auto-height):not([data-percentage]), .fp-scrollable .fp-slide:not(.fp-auto-height):not([data-percentage]) {
  min-height: calc(var(--vh, 1vh) * 100);
  min-height: 100vh;
}

.fp-overflow {
  justify-content: flex-start;
  max-height: 100vh;
}

.fp-scrollable .fp-auto-height .fp-overflow {
  max-height: none;
}

.fp-is-overflow .fp-overflow.fp-auto-height-responsive, .fp-is-overflow .fp-overflow.fp-auto-height, .fp-is-overflow > .fp-overflow {
  overflow-y: auto;
}

.fp-overflow {
  outline: none;
}

.fp-overflow.fp-table {
  display: block;
}

.fp-responsive .fp-auto-height-responsive.fp-section, .fp-responsive .fp-auto-height-responsive .fp-slide {
  height: auto !important;
  min-height: auto !important;
}

.fp-sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.fp-scroll-mac .fp-overflow::-webkit-scrollbar {
  background-color: #0000;
  width: 9px;
}

.fp-scroll-mac .fp-overflow::-webkit-scrollbar-track {
  background-color: #0000;
}

.fp-scroll-mac .fp-overflow::-webkit-scrollbar-thumb {
  background-color: #0006;
  border: 4px solid #0000;
  border-radius: 16px;
}

.fp-warning, .fp-watermark {
  z-index: 9999999;
  position: absolute;
  bottom: 0;
}

.fp-warning, .fp-watermark a {
  color: #000;
  background: #fff9;
  border-radius: 3px;
  margin: 12px;
  padding: 5px 8px;
  font-family: arial;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
}

.fp-noscroll .fp-overflow {
  overflow: hidden;
}

*, * > * {
  box-sizing: border-box;
}

html, body {
  background-color: #2b2b2b;
  margin: 0;
  padding: 0;
  font-family: Monsterrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
}

p, label {
  color: #4b4b4b;
  font-size: calc(.191205vw + 15.3881px);
  font-weight: 500;
  line-height: 1.2em;
}

@media screen and (max-width: 320px) {
  p, label {
    font-size: 16px;
  }
}

@media screen and (min-width: 1366px) {
  p, label {
    font-size: 18px;
  }
}

p.variant-white, label.variant-white {
  color: #fff;
}

p {
  margin-bottom: 20px;
}

p:last-of-type {
  margin-bottom: 0;
}

p.p-lg {
  color: #26a671;
  text-transform: uppercase;
  font-family: Geometos Rounded, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: calc(2.29446vw + 28.6577px);
}

@media screen and (max-width: 320px) {
  p.p-lg {
    font-size: 36px;
  }
}

@media screen and (min-width: 1366px) {
  p.p-lg {
    font-size: 60px;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: #1e855a;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 20px;
  padding: 0;
  font-family: Geometos Rounded, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1em;
}

h1.variant-white, h2.variant-white, h3.variant-white, h4.variant-white, h5.variant-white, h6.variant-white {
  color: #fff;
}

h1.heading-as-body-copy, h2.heading-as-body-copy, h3.heading-as-body-copy, h4.heading-as-body-copy, h5.heading-as-body-copy, h6.heading-as-body-copy {
  color: #2b2b2b;
  text-transform: initial;
  font-family: Monsterrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: calc(.191205vw + 15.3881px);
}

@media screen and (max-width: 320px) {
  h1.heading-as-body-copy, h2.heading-as-body-copy, h3.heading-as-body-copy, h4.heading-as-body-copy, h5.heading-as-body-copy, h6.heading-as-body-copy {
    font-size: 16px;
  }
}

@media screen and (min-width: 1366px) {
  h1.heading-as-body-copy, h2.heading-as-body-copy, h3.heading-as-body-copy, h4.heading-as-body-copy, h5.heading-as-body-copy, h6.heading-as-body-copy {
    font-size: 18px;
  }
}

h1 {
  font-size: calc(2.58126vw + 28.74px);
}

@media screen and (max-width: 320px) {
  h1 {
    font-size: 37px;
  }
}

@media screen and (min-width: 1366px) {
  h1 {
    font-size: 64px;
  }
}

h1.h-sm {
  font-size: calc(1.14723vw + 20.3289px);
}

@media screen and (max-width: 320px) {
  h1.h-sm {
    font-size: 24px;
  }
}

@media screen and (min-width: 1366px) {
  h1.h-sm {
    font-size: 36px;
  }
}

h2 {
  font-size: calc(.956023vw + 20.9407px);
}

@media screen and (max-width: 320px) {
  h2 {
    font-size: 24px;
  }
}

@media screen and (min-width: 1366px) {
  h2 {
    font-size: 34px;
  }
}

h2.h-lg {
  font-size: calc(.956023vw + 22.9407px);
  line-height: 1.2em;
}

@media screen and (max-width: 320px) {
  h2.h-lg {
    font-size: 26px;
  }
}

@media screen and (min-width: 1366px) {
  h2.h-lg {
    font-size: 36px;
  }
}

h3 {
  font-size: 30px;
}

@media screen and (max-width: 320px) {
  h3 {
    font-size: 30px;
  }
}

@media screen and (min-width: 1366px) {
  h3 {
    font-size: 30px;
  }
}

h4 {
  font-size: 20px;
}

@media screen and (max-width: 320px) {
  h4 {
    font-size: 20px;
  }
}

@media screen and (min-width: 1366px) {
  h4 {
    font-size: 20px;
  }
}

h5 {
  font-size: calc(.191205vw + 15.3881px);
}

@media screen and (max-width: 320px) {
  h5 {
    font-size: 16px;
  }
}

@media screen and (min-width: 1366px) {
  h5 {
    font-size: 18px;
  }
}

h6 {
  font-size: calc(.382409vw + 10.7763px);
}

@media screen and (max-width: 320px) {
  h6 {
    font-size: 12px;
  }
}

@media screen and (min-width: 1366px) {
  h6 {
    font-size: 16px;
  }
}

img {
  width: 100%;
  height: auto;
  display: block;
}

ol {
  text-align: left;
  list-style: decimal;
}

ol li {
  margin: 10px 45px;
  padding-left: 10px;
  line-height: 1.5;
}

@media (min-width: 1024px) {
  ol li {
    margin: 10px 60px;
  }
}

.hidden {
  display: none !important;
}

.semi-hidden {
  pointer-events: none;
  opacity: .2 !important;
}

.animation-layer {
  grid-area: 1 / 1;
  align-items: flex-end;
  height: 100%;
  display: flex;
  overflow: hidden;
  background-repeat: no-repeat !important;
}

.animation-layer img {
  object-fit: cover;
}

.animation-layer--clouds {
  padding-top: 50%;
}

@media (min-width: 568px) {
  .animation-layer--clouds {
    padding-top: 30%;
  }
}

@media (min-width: 768px) {
  .animation-layer--clouds {
    padding-top: 0;
  }
}

.animation-layer--clouds img {
  width: 200%;
}

.animation-layer--cottesloe {
  background: url("bg_empties.4fd90330.png") 75% bottom / 220%;
}

@media (min-width: 768px) {
  .animation-layer--cottesloe {
    background-size: cover;
  }
}

@media (min-width: 1680px) {
  .animation-layer--cottesloe {
    background-position: center;
  }
}

.animation-layer--man-drinking {
  background: url("man_drinking.e1018e17.png") 16% bottom / 180%;
}

@media (min-width: 568px) {
  .animation-layer--man-drinking {
    background-size: 150%;
  }
}

@media (min-width: 1024px) {
  .animation-layer--man-drinking {
    background-size: cover;
  }
}

@media (min-width: 1680px) {
  .animation-layer--man-drinking {
    background-position: center;
  }
}

.animation-layer--carpark-bg {
  background: url("carpark_bg.ece1b2a2.png"), linear-gradient(#313e4500 0% 80%, #313e45 80% 100%);
}

.animation-layer--carpark-fg {
  background: url("carpark_fg.a9a4eab6.png");
}

.animation-layer--garbage-truck {
  background: url("garbage_truck.02a90a50.png");
}

.animation-layer--van {
  background: url("van.d955c0dc.png");
}

.animation-layer--car {
  background: url("car.0fa6ec7c.png");
}

.animation-layer--car, .animation-layer--van, .animation-layer--garbage-truck, .animation-layer--carpark-bg, .animation-layer--carpark-fg {
  background-position: 28% 100%;
  background-size: 270%;
}

@media (min-width: 568px) {
  .animation-layer--car, .animation-layer--van, .animation-layer--garbage-truck, .animation-layer--carpark-bg, .animation-layer--carpark-fg {
    background-position: 16% bottom;
    background-size: 150%;
  }
}

@media (min-width: 1024px) {
  .animation-layer--car, .animation-layer--van, .animation-layer--garbage-truck, .animation-layer--carpark-bg, .animation-layer--carpark-fg {
    background-position: 100% bottom;
    background-size: 100%;
  }
}

@media (min-width: 1366px) {
  .animation-layer--car, .animation-layer--van, .animation-layer--garbage-truck, .animation-layer--carpark-bg, .animation-layer--carpark-fg {
    background-position: 100% 50px;
    background-size: 100%;
  }
}

@media (min-width: 1920px) {
  .animation-layer--car, .animation-layer--van, .animation-layer--garbage-truck, .animation-layer--carpark-bg, .animation-layer--carpark-fg {
    background-position: center;
  }
}

.animation-layer--man-collecting {
  background: url("man_collecting.915d3b98.png") 70% 82% / 190%;
}

@media (min-width: 568px) {
  .animation-layer--man-collecting {
    background-position: 31% 82%;
    background-size: 110%;
  }
}

@media (min-width: 1366px) {
  .animation-layer--man-collecting {
    background-position: 60% -20%;
    background-size: 110%;
  }
}

@media (min-width: 1680px) {
  .animation-layer--man-collecting {
    background-position: 60% -40%;
    background-size: 101%;
  }
}

@media (min-width: 1920px) {
  .animation-layer--man-collecting {
    background-position: -390% 0;
  }
}

.animation-layer--lady-and-boy {
  background: url("lady_and_boy.03393749.png") 80% bottom / 190%;
}

@media (min-width: 568px) {
  .animation-layer--lady-and-boy {
    background-position: 31% bottom;
    background-size: 110%;
  }
}

@media (min-width: 1366px) {
  .animation-layer--lady-and-boy {
    background-position: 60% 0;
    background-size: 110%;
  }
}

@media (min-width: 1680px) {
  .animation-layer--lady-and-boy {
    background-position: 60% 0;
    background-size: 100%;
  }
}

@media (min-width: 1920px) {
  .animation-layer--lady-and-boy {
    background-position: 70%;
  }
}

.animation-layer--bridge {
  background: url("bg_bridge.7efdafd3.png") 30% bottom / 220%;
}

@media (min-width: 768px) {
  .animation-layer--bridge {
    background-size: cover;
  }
}

@media (min-width: 1680px) {
  .animation-layer--bridge {
    background-position: center;
  }
}

@media (min-width: 768px) {
  .animation-layer--bridge {
    background-position: 50% 70%;
  }
}

.animation-layer--bridge-clouds {
  background: url("bridge_clouds.c45db3bc.png") 0 86% / 100%;
  width: 200%;
}

@media (min-width: 768px) {
  .animation-layer--bridge-clouds {
    background-position: 0 73%;
  }
}

@media (min-width: 1366px) {
  .animation-layer--bridge-clouds {
    background-position: 0 140px;
  }
}

@media (min-width: 1680px) {
  .animation-layer--bridge-clouds {
    background-position: 0 44%;
  }
}

.animation-layer--man-collecting-no-bag {
  background: url("man_collecting_no_bag.06dba67e.png");
}

.animation-layer--man-collecting-bag {
  transform-origin: 75% 72%;
  background: url("man_collecting_bag.824cfadc.png");
}

.animation-layer--man-collecting-bag, .animation-layer--man-collecting-no-bag {
  background-position: 100% 100%;
  background-size: 200%;
}

@media (min-width: 768px) {
  .animation-layer--man-collecting-bag, .animation-layer--man-collecting-no-bag {
    background-size: cover;
  }
}

@media (min-width: 1680px) {
  .animation-layer--man-collecting-bag, .animation-layer--man-collecting-no-bag {
    background-position: center;
  }
}

@media (min-width: 768px) {
  .animation-layer--man-collecting-bag, .animation-layer--man-collecting-no-bag {
    background-position: 50% 80%;
  }
}

.animation-layer--ibis {
  background-position: 100% 70%;
  background-size: 220%;
}

@media (min-width: 768px) {
  .animation-layer--ibis {
    background-size: cover;
  }
}

@media (min-width: 1680px) {
  .animation-layer--ibis {
    background-position: center;
  }
}

.animation-layer--tip-bg, .animation-layer--tip-fg {
  background-position: 100% bottom;
  background-size: 220%;
}

@media (min-width: 768px) {
  .animation-layer--tip-bg, .animation-layer--tip-fg {
    background-size: cover;
  }
}

@media (min-width: 1680px) {
  .animation-layer--tip-bg, .animation-layer--tip-fg {
    background-position: center;
  }
}

.animation-layer--tip-bg {
  background-image: url("tip_bg.cb5dc90d.png");
}

.animation-layer--tip-fg {
  background-image: url("tip_fg.e7093272.png");
}

.animation-layer--ibis {
  background-image: url("tip_ibis.4540b8ad.png");
  background-position: 110% 0;
}

@media (min-width: 1366px) {
  .animation-layer--ibis {
    background-position: unset;
  }
}

.animation-layer--backyard {
  background-image: url("backyard.aa7288c3.png");
  background-position: 75% 125%;
  background-size: 270%;
}

@media (min-width: 320px) {
  .animation-layer--backyard {
    background-position: 75% bottom;
  }
}

@media (min-width: 568px) {
  .animation-layer--backyard {
    background-size: 100%;
  }
}

@media (min-width: 1366px) {
  .animation-layer--backyard {
    background-position: 50% 85px;
  }
}

@media (min-width: 1920px) {
  .animation-layer--backyard {
    background-position: 50% -50px;
  }
}

.animation-layer--man-arms {
  transform-origin: 31% 62%;
  background-image: url("man_arms.23a5e94c.png");
}

.animation-layer--man-hold-bottles {
  background-image: url("man_no_arms.9b7bc59c.png");
}

.animation-layer--man-arms, .animation-layer--man-hold-bottles {
  background-position: -190% 130%;
  background-size: 270%;
}

@media (min-width: 360px) {
  .animation-layer--man-arms, .animation-layer--man-hold-bottles {
    background-position: 30% 120%;
    background-size: 220%;
  }
}

@media (min-width: 375px) {
  .animation-layer--man-arms, .animation-layer--man-hold-bottles {
    background-position: 30% 200%;
    background-size: 190%;
  }
}

@media (min-width: 390px) {
  .animation-layer--man-arms, .animation-layer--man-hold-bottles {
    background-position: 40% bottom;
    background-size: 200%;
  }
}

@media (min-width: 568px) {
  .animation-layer--man-arms, .animation-layer--man-hold-bottles {
    background-position: bottom;
    background-size: 112%;
  }
}

@media (min-width: 1024px) {
  .animation-layer--man-arms, .animation-layer--man-hold-bottles {
    background-position: 50% 130px;
    background-size: cover;
  }
}

@media (min-width: 1920px) {
  .animation-layer--man-arms, .animation-layer--man-hold-bottles {
    background-position: 50% 0;
  }
}

.animation-layer--landfill-bird {
  background-image: url("tip_ibis.4540b8ad.png");
  background-position: 120% 550px;
  background-size: 250%;
  transform: scaleX(-1);
}

@media (min-width: 568px) {
  .animation-layer--landfill-bird {
    background-position: 120% 400px;
    background-size: 250%;
  }
}

@media (min-width: 768px) {
  .animation-layer--landfill-bird {
    background-position: 140% 450px;
    background-size: 200%;
  }
}

@media (min-width: 1366px) {
  .animation-layer--landfill-bird {
    background-position: 210% 400px;
    background-size: 150%;
  }
}

.animation-layer--landfill {
  background-image: url("landfill_monster.2f1811af.jpg");
}

.animation-layer--landfill-mouth {
  background-image: url("landfill_monster_mouth.4bff6673.png");
}

.animation-layer--landfill-arm {
  transform-origin: 80% 100%;
  background-image: url("landfill_monster_arm.c30147fc.png");
}

.animation-layer--landfill, .animation-layer--landfill-mouth, .animation-layer--landfill-arm {
  background-position: 90% 110%;
  background-size: 140%;
}

@media (min-width: 768px) {
  .animation-layer--landfill, .animation-layer--landfill-mouth, .animation-layer--landfill-arm {
    background-position: 90% 400px;
    background-size: 120%;
  }
}

@media (min-width: 1366px) {
  .animation-layer--landfill, .animation-layer--landfill-mouth, .animation-layer--landfill-arm {
    background-position: 0 430px;
    background-size: 100%;
  }
}

.animation-depth-1 {
  z-index: 1;
}

.animation-depth-2 {
  z-index: 2;
}

.animation-depth-3 {
  z-index: 3;
}

.animation-depth-4 {
  z-index: 4;
}

.animation-depth-5 {
  z-index: 5;
}

.animation-depth-6 {
  z-index: 6;
}

.animation-depth-7 {
  z-index: 7;
}

.animation-depth-8 {
  z-index: 8;
}

.blip {
  cursor: pointer;
  z-index: 50;
  position: absolute;
}

.blip:focus .blip__bg, .blip:hover .blip__bg {
  background-color: #8fc640;
}

.blip__container {
  grid-template-rows: 75px;
  grid-template-columns: 75px;
  place-items: center;
  display: grid;
}

@media (min-width: 1024px) {
  .blip__container {
    grid-template-rows: 100px;
    grid-template-columns: 100px;
  }
}

.blip__layer {
  grid-area: 1 / 1;
}

.blip__bg {
  z-index: 2;
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: 0 0 28px 8px #a0ffd8;
}

@media (min-width: 1024px) {
  .blip__bg {
    width: 75px;
    height: 75px;
  }
}

.blip__icon {
  z-index: 3;
}

.blip__icon img {
  width: auto;
  max-width: 100%;
}

.blip[data-blip-id="donate-can"] {
  bottom: 10%;
  right: 10%;
}

@media (min-width: 1024px) {
  .blip[data-blip-id="donate-can"] {
    bottom: 10%;
    right: 15%;
  }
}

.blip[data-blip-id="donate-bottle"] {
  bottom: 15%;
  right: 40%;
}

@media (min-width: 1024px) {
  .blip[data-blip-id="donate-bottle"] {
    bottom: 16%;
    right: 28%;
  }
}

.blip[data-blip-id="donate-juice-box"] {
  bottom: 12%;
  right: 30%;
}

@media (min-width: 768px) {
  .blip[data-blip-id="donate-juice-box"] {
    bottom: 10%;
    right: 44%;
  }
}

@media (min-width: 1024px) {
  .blip[data-blip-id="donate-juice-box"] {
    bottom: 16%;
    right: 48%;
  }
}

@media (min-width: 1366px) {
  .blip[data-blip-id="donate-juice-box"] {
    bottom: 8%;
    right: 58%;
  }
}

.blip[data-blip-id="donate-juice-box"] img {
  transform: rotate(100deg);
}

.blip[data-blip-id="home-bottle"] {
  bottom: 2%;
  right: 75%;
  transform: rotate(280deg);
}

@media (min-width: 1024px) {
  .blip[data-blip-id="home-bottle"] {
    bottom: 4%;
    right: 74%;
  }
}

.blip[data-blip-id="home-can"] {
  bottom: 12%;
  left: 60%;
}

@media (min-width: 1024px) {
  .blip[data-blip-id="home-can"] {
    bottom: 22%;
    left: 82%;
  }
}

.blip[data-blip-id="home-juice-box"] {
  bottom: 29%;
  left: 50%;
}

.blip[data-blip-id="home-juice-box"] img {
  transform: rotate(100deg);
}

@media (min-width: 568px) {
  .blip[data-blip-id="home-juice-box"] {
    bottom: 35%;
    left: 8%;
  }
}

@media (min-width: 1024px) {
  .blip[data-blip-id="home-juice-box"] {
    bottom: 30%;
    left: 8%;
  }
}

.blip[data-blip-id="home-wa-money"] {
  bottom: 10%;
  right: 52%;
}

.blip[data-blip-id="home-wa-money"] img {
  max-width: 40px !important;
}

@media (min-width: 768px) {
  .blip[data-blip-id="home-wa-money"] {
    bottom: 7%;
    right: 38%;
  }

  .blip[data-blip-id="home-wa-money"] img {
    max-width: 50px !important;
  }
}

.blip[data-blip-id="home-world"] {
  bottom: 2%;
  left: 5%;
}

.blip[data-blip-id="home-world"] img {
  max-width: 40px !important;
}

@media (min-width: 768px) {
  .blip[data-blip-id="home-world"] {
    bottom: 10%;
    left: 35%;
  }

  .blip[data-blip-id="home-world"] img {
    max-width: 50px !important;
  }
}

a.button {
  display: inline-block;
}

.button {
  cursor: pointer;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  border-style: solid;
  border-width: 2px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin: 16px 10px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4375rem;
  text-decoration: none;
  transition: background-color .25s ease-in-out, border-color .25s ease-in-out, color .25s ease-in-out, opacity .25s ease-in-out;
  display: inline-flex;
}

.button i {
  text-decoration: none;
  display: inline-block;
}

.button i:before {
  transition: color .25s ease-in-out;
}

@media (min-width: 1024px) {
  .button {
    line-height: 1.75rem;
  }
}

.button:focus, .button:hover, .button:active {
  outline: none;
}

.button--hidden-mobile {
  display: none !important;
}

@media (min-width: 1024px) {
  .button--hidden-mobile {
    display: inline-flex;
  }

  .button--hidden-desktop {
    display: none !important;
  }
}

.button--variant-1 {
  color: #fff;
  background-color: #1e855a;
  border: none;
  min-width: 166px;
}

.button--variant-1:hover, .button--variant-1:active, .button--variant-1:focus {
  color: #1e855a;
  background-color: #fff;
  border-color: #1e855a;
}

.button--variant-1:hover i:before, .button--variant-1:active i:before, .button--variant-1:focus i:before {
  color: #1e855a;
}

.button--variant-1:disabled {
  opacity: .5;
}

.button--variant-2 {
  color: #1e855a;
  background-color: #fff;
  border-color: #fff;
}

.button--variant-2 i:before {
  color: #1e855a;
}

.button--variant-2:hover, .button--variant-2:active, .button--variant-2:focus {
  color: #fff;
  background-color: #1e855a;
  border-color: #fff;
}

.button--variant-2:hover i:before, .button--variant-2:active i:before, .button--variant-2:focus i:before {
  color: #fff;
}

.button--variant-2:disabled {
  opacity: .5;
}

.button--variant-3 {
  color: #fff;
  background-color: #0000;
  border-color: #0000;
}

.button--variant-3:hover, .button--variant-3:active, .button--variant-3:focus {
  border-width: .125rem;
  border-color: #fff;
}

.button--variant-4 {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button--variant-4:hover, .button--variant-4:active, .button--variant-4:focus {
  color: #fff;
  background-color: #1e855a;
  border-width: .125rem;
  border-color: #1e855a;
}

.button--variant-4:hover i:before, .button--variant-4:active i:before, .button--variant-4:focus i:before {
  color: #1e855a;
}

@media (min-width: 1024px) {
  .button--variant-4 {
    color: #1e855a;
    border-color: #1e855a;
  }

  .nav-white .button--variant-4 {
    color: #fff;
    border-color: #fff;
  }
}

.button--variant-5 {
  align-items: initial;
  color: #1e855a;
  justify-content: initial;
  line-height: initial;
  background-color: #0000;
  border: .1875rem solid #fff;
  border-radius: 31.25rem;
  margin: 1.875rem auto 0;
  padding: 1.25rem 1rem 1.25rem 1.5rem;
  display: block;
}

@media (min-width: 1024px) {
  .button--variant-5 {
    margin: 3.125rem auto 0;
    padding: 1.75rem 1.375rem 1.75rem 2.05rem;
  }
}

.button--variant-5 i {
  font-size: 2.1875rem;
}

@media (min-width: 1024px) {
  .button--variant-5 i {
    font-size: 3.125rem;
  }
}

.button--variant-5:hover, .button--variant-5:active, .button--variant-5:focus {
  color: #1e855a;
  background-color: #fff;
}

.button--variant-5:hover i:before, .button--variant-5:active i:before, .button--variant-5:focus i:before {
  color: #1e855a;
}

.button--variant-6 {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button--variant-6:hover, .button--variant-6:active, .button--variant-6:focus {
  color: #1e855a;
  background-color: #fff;
}

.button--variant-6:hover i:before, .button--variant-6:active i:before, .button--variant-6:focus i:before {
  color: #1e855a;
}

.button span {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  white-space: nowrap;
  width: .0625rem;
  height: .0625rem;
  position: absolute;
  overflow: hidden;
}

.button-container {
  margin-bottom: 20px;
}

.copyright {
  color: #fff;
  text-align: center;
  padding: 20px 20px 45px;
  line-height: 2em;
}

@media (min-width: 768px) {
  .copyright {
    margin: 45px 20px;
  }
}

.copyright a {
  color: #fff;
}

.copyright a:hover, .copyright a:focus {
  text-decoration: none;
}

.fp-watermark {
  opacity: 0;
}

.comparison-list {
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  display: flex;
}

.comparison-list__table {
  font-family: Geometos Rounded, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
}

.comparison-list__table img {
  width: 75%;
  margin: 0 auto;
  display: block;
}

@media (min-width: 768px) {
  .comparison-list__table img {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .comparison-list__table img {
    width: auto;
  }
}

.comparison-list__table li {
  text-align: center;
  margin: 0 .9375rem;
}

.comparison-list__table li:first-child {
  margin-top: 1.125rem;
}

@media (min-width: 768px) {
  .comparison-list__table li:first-child {
    margin-top: 3.125rem;
  }
}

.comparison-list__table li:nth-child(3) {
  font-size: 1.25rem;
}

.comparison-list__table li:nth-child(3) span {
  font-size: 2.5rem;
  display: block;
}

.content {
  text-align: center;
  z-index: 10;
  border-radius: 25px;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 25px;
  display: flex;
}

@media (min-width: 768px) {
  .content {
    padding: 40px 30px;
  }
}

.content--white {
  background-color: #fffc;
}

.content--brown {
  background-color: #3d3c34cc;
}

.content--black {
  background-color: #1e2120cc;
}

.content--transparent {
  background-color: #fff0;
}

.content .heading-container {
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin: 0 0 20px;
  display: flex;
}

@media (min-width: 768px) {
  .content .heading-container {
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
  }
}

.content .heading-container h1 {
  margin: 0;
}

.content .heading-container-date {
  max-width: 124px;
}

@media (min-width: 568px) {
  .content .heading-container-date {
    max-width: 130px;
  }
}

@media (min-width: 1024px) {
  .content .heading-container-date {
    max-width: 168px;
  }
}

.content .text-container {
  background: #ffffffb3;
  border-radius: 10px;
  padding: 20px;
}

.content .logo-container {
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
}

@media (min-width: 768px) {
  .content .logo-container {
    gap: 50px;
  }
}

.content .logo-container .logo {
  flex-grow: 1;
  width: 100%;
}

.content .logo-container .logo-cfc {
  max-width: 90px;
}

@media (min-width: 768px) {
  .content .logo-container .logo-cfc {
    max-width: 154px;
  }
}

.content .logo-container .logo-coles {
  max-width: 100px;
}

@media (min-width: 768px) {
  .content .logo-container .logo-coles {
    max-width: 181px;
  }
}

.content .logo-container img.variant-white {
  filter: brightness(0) invert();
}

.section--donate-coles .content p.variant-white {
  font-size: 12px;
}

@media (min-width: 768px) {
  .section--donate-coles .content p.variant-white {
    font-size: 18px;
  }
}

.counter {
  margin: 0;
  font-family: Geometos Rounded, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
}

.counter__value {
  margin: .5rem 0 0;
  font-size: 1.7rem;
  line-height: 2.3rem;
  display: block;
}

@media (min-width: 1024px) {
  .counter__value {
    font-size: 4rem;
    line-height: 5rem;
  }
}

.counter__unit {
  font-size: 1rem;
  display: block;
}

@media (min-width: 1024px) {
  .counter__unit {
    font-size: 1.625rem;
  }
}

.counter button {
  margin-bottom: 0;
}

.decision {
  z-index: 2;
  flex-direction: column;
  grid-area: 1 / 1;
  justify-content: center;
  height: 100%;
  display: flex;
  background: linear-gradient(#0e7b55 0%, #a1de52 100%) !important;
}

.decision__content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

@media (min-width: 768px) {
  .decision__content {
    flex-direction: row;
  }
}

.decision__choice {
  cursor: pointer;
  background-color: #fff;
  border-radius: 25px;
  width: 9.75rem;
  height: 9.75rem;
  margin: 20px .3125rem;
  padding: 7.1875rem 0 0;
  display: block;
  position: relative;
  overflow: hidden;
}

@media (min-width: 568px) {
  .decision__choice {
    height: unset;
    width: 12.5rem;
  }
}

@media (min-width: 768px) {
  .decision__choice {
    width: 15.625rem;
  }
}

@media (min-width: 1024px) {
  .decision__choice {
    width: 18.75rem;
  }
}

@media (min-width: 1366px) {
  .decision__choice {
    width: 25rem;
  }
}

.decision__choice:hover span, .decision__choice:active span, .decision__choice:focus span {
  color: #1e855a;
}

.decision__choice span {
  color: #0e7b55;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-family: Geometos Rounded, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  transition: color .25s ease-in-out;
  display: block;
  position: absolute;
  top: 1rem;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  .decision__choice span {
    font-size: 1.625rem;
    line-height: 1.875rem;
    top: 2.8125rem;
  }
}

.decision__choice video {
  pointer-events: none;
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
}

.decision__choice--1 {
  padding: 4.375rem 0 0;
}

@media (min-width: 768px) {
  .decision__choice--1 {
    padding: 4.4375rem 0 0;
  }
}

.decision__choice--2 {
  padding: 4.375rem 0 0;
}

@media (min-width: 768px) {
  .decision__choice--2 {
    padding: 7.1875rem 1.875rem 0;
  }
}

.decision__operand {
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  padding: 0 2.5rem;
  font-family: Geometos Rounded, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 30px;
  display: block;
}

@media (min-width: 1024px) {
  .decision__operand {
    font-size: 80px;
  }
}

.footer-static {
  z-index: 100;
  background-image: linear-gradient(#0000 0%, #0000004d 100%);
  justify-content: center;
  width: 100vw;
  max-height: 114px;
  display: flex;
  position: fixed;
  bottom: 0;
}

.footer-static.nav-white {
  display: none;
}

.footer-container {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 1440px;
  padding: 15px;
  display: grid;
}

.footer__logo-coles {
  grid-area: 1 / 1;
  justify-self: flex-end;
  width: 96px;
}

@media (min-width: 1024px) {
  .footer__logo-coles {
    width: 114px;
  }
}

.grid-container {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  align-items: center;
  height: 100%;
  display: grid;
  background-repeat: no-repeat !important;
}

.fp-overflow {
  height: 100% !important;
}

.section {
  background-repeat: no-repeat !important;
}

.section--pledge {
  background: url("bg-4.48414f98.jpg") center / cover;
}

.section--home-redeemed, .section--home-reinvented, .section--home-dont-feed, .section--donate-chosen-support, .section--donate-empties-day {
  background: url("texture.2662d0c5.png") bottom / cover, linear-gradient(#fff 0%, #c6fafe 100%) bottom / cover;
}

.section__animation-container {
  grid-area: 1 / 1;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  height: 100%;
  display: grid;
  overflow: hidden;
}

.section__content-container {
  grid-area: 1 / 1;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: calc(90px + 1%);
  padding-right: 5%;
  display: flex;
}

@media (min-width: 390px) {
  .section__content-container {
    justify-content: center;
    padding-right: 0;
  }
}

.section__content-container .content {
  width: 85%;
  max-width: 640px;
}

#homePage .section__content-container .content {
  max-width: 760px;
}

.section__content-container .content--wide {
  max-width: 1200px !important;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
  background-color: #fff;
}

.fp-nav--hidden {
  opacity: .25 !important;
}

.fp-slides {
  width: 100%;
}

#fp-nav.fp-right {
  right: 1px;
}

.header {
  z-index: 1;
  background: linear-gradient(#ffffff80 24.68%, #fff0 101.97%);
  width: 100vw;
  min-height: 90px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header.nav-white {
  background: none;
}

.header.nav-white .icon--menu:before {
  color: #fff;
}

.header.nav-white .header__logo img {
  filter: brightness(0) invert();
}

.header .icon--cross, .header .icon--menu {
  transition: opacity .25s ease-in-out;
  position: absolute;
  top: 25%;
  left: 20%;
}

.header .navigation__control {
  border: none;
}

.header .icon--menu:before {
  color: #1e855a;
}

.header .icon--cross {
  left: 30%;
}

.header__bounding-container {
  text-align: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 0 0 15px;
  display: flex;
  position: relative;
}

.header__call-to-action {
  margin: 0 auto;
  font-size: .625rem;
}

@media (min-width: 320px) {
  .header__call-to-action {
    font-size: .75rem;
  }
}

@media (min-width: 568px) {
  .header__call-to-action {
    font-size: .875rem;
  }
}

.header__logo {
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  transition: transform .25s ease-in-out;
  display: block;
}

@media (min-width: 768px) {
  .header__logo {
    width: 62px;
    height: 62px;
    transform: scale(.75);
  }
}

@media (min-width: 1024px) {
  .header__logo {
    transform: initial;
  }
}

.modal {
  opacity: 1;
  pointer-events: all;
  z-index: 200;
  background-color: #000000bf;
  justify-content: center;
  align-items: center;
  transition: opacity .25s ease-in-out;
  display: flex;
  position: absolute;
  inset: 0;
}

.modal p {
  font-size: 16px;
}

.modal--constrained-video {
  max-width: 960px;
}

.modal--constrained-video #videoOne {
  height: 100vh;
  max-height: 550px;
}

.modal--closed {
  opacity: 0;
  pointer-events: none;
}

.modal--video {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.modal--video .modal__container {
  width: 100%;
  margin: 0 auto;
}

.modal__container--video .modal__content {
  padding: 0;
}

.modal__content {
  text-align: center;
  background-color: #fff;
  border-radius: 25px;
  flex-direction: column;
  min-width: 300px;
  max-width: 640px;
  margin: 10px;
  padding: 30px 7%;
  display: flex;
}

.modal__content--small {
  max-width: 450px;
}

.modal__content--video {
  width: 100%;
  max-width: 1080px;
  height: 720px;
  margin: 0 auto;
}

.modal__content--video iframe {
  height: 100%;
  min-height: 560px;
}

.modal__content img {
  align-self: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 20px;
}

.modal__content iframe {
  width: 100%;
  height: auto;
  margin: 20px 0;
}

.modal__content a {
  border: 2px solid #1e855a;
  align-self: center;
  margin-top: 20px;
  text-decoration: none;
}

.modal__content a:hover, .modal__content a:focus {
  border-color: #1e855a;
  text-decoration: underline;
}

.modal__close {
  cursor: pointer;
  align-self: flex-end;
  margin: 0 0 20px 20px;
}

.modal__close:before {
  color: #1e855a;
  font-size: 26px;
}

.navigation {
  background: #1e855a;
  width: 300px;
  max-width: 310px;
  transition: right .25s ease-in-out;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100vw;
}

@media (min-width: 1024px) {
  .navigation {
    background: initial;
    bottom: initial;
    max-width: initial;
    padding: initial;
    position: initial;
    right: initial;
    top: initial;
    width: initial;
    justify-content: flex-end;
    align-items: center;
    transition: initial;
    display: flex;
  }
}

@media (prefers-reduced-motion) {
  .navigation {
    transition: none;
  }
}

.navigation--open {
  display: block;
  right: 0;
}

@media (min-width: 1024px) {
  .navigation--open {
    right: auto;
  }
}

.navigation__control {
  z-index: 1;
  width: 48px;
  height: 3rem;
  margin: 0;
  position: absolute;
  top: 15px;
  right: 15px;
}

@media (min-width: 1024px) {
  .navigation__control {
    display: none !important;
  }
}

.navigation__list {
  margin-top: 100px;
}

@media (min-width: 1024px) {
  .navigation__list {
    justify-content: flex-end;
    align-items: center;
    margin-top: 0;
    display: flex;
  }
}

.navigation__list-item {
  text-align: left;
}

@media (min-width: 1024px) {
  .navigation__list-item:last-of-type {
    margin-right: 0;
  }
}

.navigation__link {
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  text-underline-offset: 4px;
  margin: 0 0 15px;
  padding: 15px;
  font-family: Monsterrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  display: block;
}

@media (min-width: 1024px) {
  .navigation__link {
    color: #1e855a;
    text-align: center;
    margin: 0;
  }

  .nav-white .navigation__link {
    color: #fff;
  }
}

.navigation__link:hover, .navigation__link:focus {
  text-decoration: underline;
}

.navigation .button {
  margin: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1em;
}

@media (min-width: 1024px) {
  .navigation .button {
    margin: 0 10px;
  }
}

.navigation.nav-white .button {
  color: #fff !important;
  border-color: #fff !important;
}

.navigation.nav-white .button:hover, .navigation.nav-white .button:active, .navigation.nav-white .button:focus {
  color: #1e855a !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.overlay {
  z-index: 1000;
  background-color: #1e855a;
  transition: background-color .25s ease-in-out 1s;
  position: absolute;
  inset: 0;
}

.overlay--loaded {
  pointer-events: none;
  background-color: #0000;
}

.pledge {
  background-color: #fff;
  border-radius: 20px;
  padding: 40px;
}

.pledge__counter {
  color: #1e855a;
  padding-bottom: 20px;
  font-family: Geometos Rounded, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: calc(.382409vw + 14.7763px);
  font-weight: 400;
}

@media screen and (max-width: 320px) {
  .pledge__counter {
    font-size: 16px;
  }
}

@media screen and (min-width: 1366px) {
  .pledge__counter {
    font-size: 20px;
  }
}

.pledge__counter span {
  font-size: calc(.382409vw + 30.7763px);
}

@media screen and (max-width: 320px) {
  .pledge__counter span {
    font-size: 32px;
  }
}

@media screen and (min-width: 1366px) {
  .pledge__counter span {
    font-size: 36px;
  }
}

.pledge__promise {
  color: #1e855a;
  text-align: left;
  font-size: 24px;
  font-weight: 700;
}

.pledge__footer ul {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0;
  display: flex;
}

.pledge__footer ul li {
  margin: 0 .3125rem 0 0;
  position: relative;
}

.pledge__footer ul li:last-of-type:after {
  display: none;
}

.pledge__footer ul li:after {
  color: #1e855a;
  content: "/";
  display: block;
  position: absolute;
  top: 0;
  right: -.3125rem;
}

.pledge__footer ul li a {
  color: #1e855a;
  padding: .5rem;
  font-weight: 500;
  text-decoration: none;
}

.pledge__footer ul li a:hover, .pledge__footer ul li a:focus {
  text-decoration: underline;
}

.pledge__name {
  border: none;
  border-bottom: 2px solid #1e855a;
  outline: none;
  width: 90%;
  font-size: 24px;
  display: inline-block;
}

.pledge__error {
  color: red;
  flex: 1 0 100%;
  margin: 0 0 0 2.0625rem;
  display: block;
}

.pledge__checkbox-group {
  text-align: left;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: .625rem 0 0;
  padding: 0;
  display: flex;
}

@media (min-width: 768px) {
  .pledge__checkbox-group {
    margin: 1rem 0 0;
  }
}

.pledge__checkbox-group:last-of-type {
  margin: .625rem 0;
}

@media (min-width: 768px) {
  .pledge__checkbox-group:last-of-type {
    margin: 1rem 0;
  }
}

.pledge__checkbox {
  appearance: none;
  background-color: #fff;
  border: .0625rem solid #2b2b2b;
  border-radius: 0;
  margin: 0 .75rem -.2125rem 0;
  padding: .5625rem;
  display: inline-block;
  position: relative;
}

.pledge__checkbox:active:checked, .pledge__checkbox:active {
  background-color: #19704c;
}

.pledge__checkbox:checked {
  color: #fff;
  background-color: #1e855a;
  border: .0625rem solid #0000;
  font-family: icomoon !important;
}

.pledge__checkbox:checked:after {
  color: #fff;
  content: "";
  font-size: .875rem;
  position: absolute;
  top: .0625rem;
  left: .125rem;
}

.pledge__checkbox-label {
  flex: 1;
}

@media (max-width: 767px) {
  .pledge__checkbox-label {
    font-size: .875rem;
    line-height: .875rem;
  }
}

.pledge__step--closed {
  display: none;
}

.pledge__social-links-list {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 1.5625rem 0;
  display: flex;
}

.pledge__social-links-list li {
  margin: 0 1.25rem;
  display: block;
}

.pledge__social-links-list a {
  background-color: #1e855a;
  border-radius: 3.125rem;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 1.125rem .875rem .625rem;
  font-size: 0;
  text-decoration: none;
  display: flex;
}

.pledge__social-links-list a i {
  line-height: .75rem;
}

.pledge__social-links-list a i:before {
  font-size: 1.375rem;
}

.section-navigation {
  grid-area: 1 / 1;
  place-self: center;
  transition: opacity .25s ease-in-out;
}

.section-navigation__button {
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  transition: opacity .25s ease-in-out;
  display: flex;
}

.section-navigation__button i {
  margin-top: -.3125rem;
  transform: rotate(90deg);
}

.section-navigation__button i:before {
  color: #1e855a;
  text-align: center;
  vertical-align: middle;
  font-size: 24px;
  line-height: 1em;
}

.section-navigation__button:hover, .section-navigation__button:focus, .section-navigation__button:active {
  opacity: 1;
  background-color: #1e855a;
}

.section-navigation__button:hover i:before, .section-navigation__button:focus i:before, .section-navigation__button:active i:before {
  color: #fff;
}

.illustration {
  background-size: cover;
  position: absolute;
}

.illustration--girl {
  background-image: url("girl.68c80395.png");
  width: 26.9375rem;
  height: 37.9375rem;
  bottom: 13.125rem;
  left: 9.375rem;
}

.illustration--girl-and-dog {
  opacity: .1;
  background-image: url("girl-and-dog.b0f0f0ce.png");
  width: 140px;
  height: 238.667px;
  margin-left: -4.375rem;
  bottom: -15%;
  left: 50%;
}

@media (min-width: 768px) {
  .illustration--girl-and-dog {
    width: 26.25rem;
    height: 44.75rem;
    bottom: 0;
    left: 10%;
  }
}

.illustration--bin-chicken-1 {
  background-image: url("bin-chicken-1.e51b0bdc.png");
  width: 16.4375rem;
  height: 20.25rem;
  bottom: 25.625rem;
  left: 81.875rem;
}

.illustration--bin-chicken-2 {
  background-image: url("bin-chicken-2.8194390f.png");
  width: 14.4375rem;
  height: 9.3125rem;
  bottom: 24.0625rem;
  left: 18.4375rem;
}

.illustration--bottle {
  opacity: 1;
  background-image: url("bottle.4a752b97.png");
  width: 9.0625rem;
  height: 4.6875rem;
  bottom: 24.0625rem;
  left: 18.4375rem;
}

.sr--only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: .0625rem;
  height: .0625rem;
  margin: -.0625rem;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

@media (max-width: 1023px) {
  .sr--mobile-only {
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
    width: .0625rem;
    height: .0625rem;
    margin: -.0625rem;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }
}

.vp--block-mobile-only {
  display: block;
}

@media (min-width: 1024px) {
  .vp--block-mobile-only {
    display: none !important;
  }
}

.vp--block-desktop-only {
  display: none !important;
}

@media (min-width: 1024px) {
  .vp--block-desktop-only {
    display: block;
  }
}

.vp--flex-mobile-only {
  display: flex;
}

@media (min-width: 1024px) {
  .vp--flex-mobile-only {
    display: none !important;
  }
}

.vp--flex-desktop-only {
  display: none !important;
}

@media (min-width: 1024px) {
  .vp--flex-desktop-only {
    display: flex;
  }
}

.yt-video--16-9 {
  height: 0;
  margin: 0 0 20px;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.yt-video--16-9 iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.nobr {
  white-space: nowrap;
}

.margin-top--20 {
  margin-top: 20px;
}

.margin-top--40 {
  margin-top: 40px;
}

/*# sourceMappingURL=index.1934f811.css.map */
