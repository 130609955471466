/** Main Utilities Loader */

// Utilities – utilities and helper classes with ability to override anything
// which goes before in the triangle, eg. hide helper class

// Force content to be rendered 'invisible' to the browser, but spoken by a
// screenreader.
.sr {
  &--only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 0.0625rem;
    margin: -0.0625rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 0.0625rem;
  }
  &--mobile-only {
    // color: white !important;
    // Render 'invisible' only on desktop with a non-standard breakpoint.
    @include bp('-m-lg') {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 0.0625rem;
      margin: -0.0625rem;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 0.0625rem;
    }
  }
}

// Remove element from the DOM.
.vp {
  &--block-mobile-only {
    display: block;
    @include bp('m-lg') {
      display: none !important;
    }
  }
  &--block-desktop-only {
    display: none !important;
    @include bp('m-lg') {
      display: block;
    }
  }
  &--flex-mobile-only {
    display: flex;
    @include bp('m-lg') {
      display: none !important;
    }
  }
  &--flex-desktop-only {
    display: none !important;
    @include bp('m-lg') {
      display: flex;
    }
  }
}

.yt-video--16-9 {
  height: 0;
  margin: 0 0 20px 0;
  overflow: hidden;
  padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
  position: relative;
  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.nobr {
  white-space: nowrap;
}

.margin-top {
  &--20 {
    margin-top: 20px;
  }
  &--40 {
    margin-top: 40px;
  }
}
