/** Main Objects Loader */

// Objects – class-based selectors which define undecorated design patterns, for example media object known from OOCSS

.hidden {
  display: none !important;
}

.semi-hidden {
  opacity: 0.2 !important;
  pointer-events: none;
}
