.animation {
  &-layer {
    align-items: flex-end;
    // background-color: rgba($color: #00ff00, $alpha: 1);
    background-repeat: no-repeat !important;

    display: flex;
    grid-column: 1;
    grid-row: 1;
    height: 100%;
    overflow: hidden;

    img {
      object-fit: cover;
    }

    &--clouds {
      padding-top: 50%;
      @include bp('m-sm') {
        padding-top: 30%;
      }
      @include bp('m-md') {
        padding-top: 0;
      }
      img {
        width: 200%;
        //   position: absolute;
      }
    }

    &--cottesloe {
      background: url('../images/animation/section_empties/bg_empties.png');
      background-position: 75% bottom;
      background-size: 220%;
      @include bp('m-md') {
        background-size: cover;
      }
      @include bp('d-sm') {
        background-position: center 50%;
      }
      @include bp('d-md'){
        // background-position: center 75%;
      }
    }
    &--man-drinking {
      background: url('../images/animation/section_empties/man_drinking.png');
      background-position: 16% bottom;
      background-size: 180%;
      @include bp('m-sm') {
        background-size: 150%;
      }
      @include bp('m-lg') {
        background-size: cover;
      }
      @include bp('d-sm') {
        background-position: center 50%;
      }
    }
    &--carpark-bg {
      //   background-image: linear-gradient(180deg, rgba(46, 58, 64, 0) 0%, rgba(46, 58, 64, 1) 70%, rgba(46, 58, 64, 1) 100%);
      background: url('../images/animation/section_chosen/carpark_bg.png'), linear-gradient(180deg, rgba(49, 62, 69, 0) 0%, rgba(49, 62, 69, 0) 80%, rgba(49, 62, 69, 1) 80%, rgba(49, 62, 69, 1) 100%);
    }
    &--carpark-fg {
      background: url('../images/animation/section_chosen/carpark_fg.png');
    }
    &--garbage-truck {
      background: url('../images/animation/section_chosen/garbage_truck.png');
    }
    &--van {
      background: url('../images/animation/section_chosen/van.png');
    }
    &--car {
      background: url('../images/animation/section_chosen/car.png');
    }

    &--car,
    &--van,
    &--garbage-truck,
    &--carpark-bg,
    &--carpark-fg {
      background-position: 28% 100%;
      background-size: 270%;
      @include bp('m-sm') {
        background-position: 16% bottom;
        background-size: 150%;
      }
      @include bp('m-lg') {
        background-position: 100% bottom;
        background-size: 100%;
      }
      @include bp('m-xl') {
        background-position: 100% 50px;
        background-size: 100%;
      }
      @include bp('d-md') {
        background-position: center 50%;
      }
    }
    &--man-collecting {
      background: url('../images/animation/section_chosen/man_collecting.png');
      background-position: 70% 82%;
      background-size: 190%;
      @include bp('m-sm') {
        background-position: 31% 82%;
        background-size: 110%;
      }
      @include bp('m-xl') {
        background-position: 60% -20%;
        background-size: 110%;
      }
      @include bp('d-sm') {
        background-position: 60% -40%;
        background-size: 101%;
      }
      @include bp('d-md') {
        background-position: 70% 0%;
      }
      @include bp('d-md') {
        background-position: -390% 0%;
        // background-size: 98%;
      }
    }

    &--lady-and-boy {
      background: url('../images/animation/section_redeemed/lady_and_boy.png');
      background-position: 80% bottom;
      background-size: 190%;
      @include bp('m-sm') {
        background-position: 31% bottom;
        background-size: 110%;
      }
      @include bp('m-xl') {
        background-position: 60% 0;
        background-size: 110%;
      }
      @include bp('d-sm') {
        background-position: 60% 0;
        background-size: 100%;
      }
      @include bp('d-md') {
        background-position: 70% 50%;
      }
    }

    &--bridge {
      background: url('../images/animation/dont_feed_fill/bg_bridge.png');
      background-position: 30% bottom;

      background-size: 220%;
      @include bp('m-md') {
        background-size: cover;
      }
      @include bp('d-sm') {
        background-position: center 50%;
      }
      @include bp('m-md'){
        background-position: center 70%;
      }
    }
    &--bridge-clouds {
      background: url('../images/animation/dont_feed_fill/bridge_clouds.png');
      background-position: 0 86%;
      background-size: 100%;
      width: 200%;

      @include bp('m-md') {
        background-position: 0 73%;
      }
      @include bp('m-xl') {
        background-position: 0 140px;
      }
      @include bp('d-sm') {
        background-position: 0 44%;
      }
    }

    &--man-collecting-no-bag {
      background: url('../images/animation/dont_feed_fill/man_collecting_no_bag.png');
    }
    &--man-collecting-bag {
      background: url('../images/animation/dont_feed_fill/man_collecting_bag.png');
      transform-origin: 75% 72%;
    }
    &--man-collecting-bag,
    &--man-collecting-no-bag {
      background-position: 100% 100%;
      background-size: 200%;
      @include bp('m-md') {
        background-size: cover;
      }
      @include bp('d-sm') {
        background-position: center 50%;
      }
      @include bp('m-md'){
        background-position: center 80%;
      }
    }

    &--ibis {
      background-position: 100% 70%;
      background-size: 220%;
      @include bp('m-md') {
        background-size: cover;
      }
      @include bp('d-sm') {
        background-position: center 50%;
      }
    }

    &--tip-bg,
    &--tip-fg {
      background-position: 100% bottom;
      background-size: 220%;
      @include bp('m-md') {
        background-size: cover;
      }
      @include bp('d-sm') {
        background-position: center 50%;
      }
    }
    &--tip-bg {
      background-image: url('../images/animation/section_lair/tip_bg.png');
    }

    &--tip-fg {
      background-image: url('../images/animation/section_lair/tip_fg.png');
    }
    &--ibis {
      background-image: url('../images/animation/section_lair/tip_ibis.png');
      background-position: 110% 0;
      @include bp('m-xl') {
        background-position: unset;
      }
    }

    &--backyard {
      background-image: url('../images/animation/section_reinvented/backyard.png');
      background-position: 75% 125%;
      background-size: 270%;
      @include bp('m-xs') {
        background-position: 75% bottom;
      }
      @include bp('m-sm') {
        background-size: 100%;
      }
      @include bp('m-xl') {
        background-position: center 85px;
      }
      @include bp('m-xl') {
      }
      @include bp('d-md'){
        background-position: center -50px;
      }
    }
    &--man-arms {
      background-image: url('../images/animation/section_reinvented/man_arms.png');
      transform-origin: 31% 62%;
    }
    &--man-hold-bottles {
      background-image: url('../images/animation/section_reinvented/man_no_arms.png');
    }
    &--man-arms,
    &--man-hold-bottles {
      background-position: -190% 130%;
      background-size: 270%;
      @include bp('m-xs-360') {
        background-position: 30% 120%;
        background-size: 220%;
      }

      @include bp('m-xs-375') {
        background-position: 30% 200%;
        background-size: 190%;
      }

      @include bp('designer') {
        background-position: 40% bottom;
        background-size: 200%;
      }
      @include bp('m-sm') {
        background-position: center bottom;
        background-size: 112%;
      }
      @include bp('m-lg') {
        background-position: center 130px;
        background-size: 140%;
        background-size: cover;
      }
      @include bp('d-md'){
        background-position: center 0px;
      }
    }

    &--landfill-arm,
    &--landfill-mouth,
    &--landfill {
      // background-position: center -50%;
      // background-size: cover;
      @include bp('m-lg') {
        // background-position: 80% 150%;
      }
      @include bp('m-xl') {
        // background-position: 80% 250%;
      }
    }
    &--landfill-bird {
      background-image: url('../images/animation/section_lair/tip_ibis.png');
      background-position: 120% 550px;
      background-size: 250%;
      transform: scaleX(-1);
      // background-position: 120% 350%;
      // background-size: 250%;
      @include bp('m-sm') {
        background-position: 120% 400px;
        background-size: 250%;
      }
      @include bp('m-md') {
        background-position: 140% 450px;
        // background-size: 120%;
        background-size: 200%;
        // background-position: 90% 400px;
      }
      @include bp('m-xl') {
        background-position: 210% 400px;
        background-size: 150%;
        // background-position: 170% -50%;
        // background-size: 180%;
      }
    }
    &--landfill {
      background-image: url('../images/animation/section_landfill/landfill_monster.jpg');
    }
    &--landfill-mouth {
      background-image: url('../images/animation/section_landfill/landfill_monster_mouth.png');
    }
    &--landfill-arm {
      background-image: url('../images/animation/section_landfill/landfill_monster_arm.png');
      transform-origin: 80% 100%;
    }
    &--landfill,
    &--landfill-mouth,
    &--landfill-arm {
      background-position: 90% 110%;
      background-size: 140%;
      @include bp('m-md') {
        background-position: 90% 400px;
        background-size: 120%;
      }
      @include bp('m-xl') {
        background-position: 0 430px;
        background-size: 100%;
      }
    }
  }

  &-depth-1 {
    z-index: 1;
  }
  &-depth-2 {
    z-index: 2;
  }
  &-depth-3 {
    z-index: 3;
  }
  &-depth-4 {
    z-index: 4;
  }
  &-depth-5 {
    z-index: 5;
  }
  &-depth-6 {
    z-index: 6;
  }
  &-depth-7 {
    z-index: 7;
  }
  &-depth-8 {
    z-index: 8;
  }
}
