p,
label {
  color: $g_font-colour;
  font-weight: $g_font-weight;
  line-height: $g_line-height-lg;

  @include fluid-type($g_font-size-min, $g_font-size-max - 2);

  &.variant-white {
    color: $color--white;
  }
}

p {
  margin-bottom: 20px; //0.5em;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.p-lg {
    font-family: $font--geometos;
    @include fluid-type(36px, 60px);
    color: $color--green-light;
    text-transform: uppercase;
  }
}
