.modal {
  //
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 1;
  pointer-events: all;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity $transition--rate $transition--easing;
  z-index: 200;

  p {
    font-size: 16px;
  }

  &--constrained-video {
    max-width: 960px;
    #videoOne {
      height: 100vh;
      max-height: 550px;
    }
  }
  &--closed {
    opacity: 0;
    pointer-events: none;
  }
  &--video {
    align-items: center;
    // max-width: 720px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    .modal__container {
      margin: 0 auto;
      width: 100%;
    }
  }
  &__container {
    &--video {
      .modal__content {
        padding: 0;
      }
    }
  }
  &__content {
    background-color: $color--white;

    border-radius: 25px;
    display: flex;
    flex-direction: column;
    margin: 10px;
    max-width: $g_content-width-max;
    min-width: 300px;
    padding: 30px 7%; //$padding--small;
    text-align: center;
    &--small {
      max-width: 450px
    }
    &--video{
      height: 720px;
      margin: 0 auto;
      max-width: 1080px;
      width: 100%;
      iframe {
        height: 100%;
        min-height: 560px;
      }
    }

    img {
      align-self: center;
      margin-bottom: $padding--small;
      width: fit-content;
    }

    iframe {
      height: auto;
      margin: 20px 0;
      width: 100%;
    }
    a {
      align-self: center;
      border: 2px solid $color--green-dark;
      margin-top: $padding--small;
      text-decoration: none;

      &:hover,
      &:focus {
        border-color: $color--green-dark;
        text-decoration: underline;
      }
    }
  }
  &__close {
    align-self: flex-end;
    cursor: pointer;
    margin: 0 0 20px 20px;
    &:before {
      color: $color--green-dark;
      font-size: 26px;
    }
  }
}
