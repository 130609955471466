.blip {
  cursor: pointer;
  position: absolute;
  z-index: 50;
  // &:active {
  //   .blip__layer-1 {
  //     background-color: $color--green-yellow-dark;
  //     border: 0.25rem solid $color--white;
  //   }
  // }
  &:focus,
  &:hover {
    .blip__bg {
      background-color: $color--green-yellow-dark;
    }
  }
  &__container {
    align-items: center;
    display: grid;
    grid-template-columns: $g_blip-icon-size-min;
    grid-template-rows: $g_blip-icon-size-min;
    justify-items: center;
    @include bp('m-lg') {
      grid-template-columns: $g_blip-icon-size-max;
      grid-template-rows: $g_blip-icon-size-max;
    }
  }
  &__layer {
    grid-column: 1;
    grid-row: 1;
  }

  &__bg {
    background-color: $color--white;
    border-radius: 50%;
    box-shadow: 0px 0px 28px 8px #a0ffd8;
    height: $g_blip-bg-size-min;
    width: $g_blip-bg-size-min;
    z-index: 2;
    @include bp('m-lg') {
      height: $g_blip-bg-size-max;
      width: $g_blip-bg-size-max;
    }
  }
  &__icon {
    z-index: 3;
    img {
      max-width: 100%;
      width: auto;
    }
  }

  &[data-blip-id='donate-can'] {
    bottom: 10%;
    right: 10%;
    @include bp('m-lg') {
      bottom: 10%;
      right: 15%;
    }
  }
  &[data-blip-id='donate-bottle'] {
    bottom: 15%;
    right: 40%;
    @include bp('m-lg') {
      bottom: 16%;
      right: 28%;
    }
  }
  &[data-blip-id='donate-juice-box'] {
    bottom: 12%;
    right: 30%;
    @include bp('m-md') {
      bottom: 10%;
      right: 44%;
    }
    @include bp('m-lg') {
      bottom: 16%;
      right: 48%;
    }
    @include bp('m-xl') {
      bottom: 8%;
      right: 58%;
    }
    img {
      transform: rotate(100deg);
    }
  }
  &[data-blip-id='home-bottle'] {
    bottom: 2%;
    right: 75%;
    transform: rotate(280deg);
    @include bp('m-lg') {
      bottom: 4%;
      right: 74%;
    }
  }
  &[data-blip-id='home-can'] {
    bottom: 12%;
    left: 60%;
    @include bp('m-lg') {
      bottom: 22%;
      left: 82%;
    }
  }
  &[data-blip-id='home-juice-box'] {
    bottom: 29%;
    left: 50%;
    img {
      transform: rotate(100deg);
    }
    @include bp('m-sm') {
      bottom: 35%;
      left: 8%;
    }
    @include bp('m-lg') {
      bottom: 30%;
      left: 8%;
    }
  }
  &[data-blip-id='home-wa-money'] {
    bottom: 10%;
    right: 52%;
    img {
      max-width: 40px !important;
    }
    // @include bp('m-sm') {
    //   bottom: 32%;
    //   right: 18%;
    // }
    @include bp('m-md') {
      bottom: 7%;
      right: 38%;
      img {
        max-width: 50px !important;
      }
    }
  }
  &[data-blip-id='home-world'] {
    img {
      max-width: 40px !important;
    }
    bottom: 2%;
    left: 5%;
    @include bp('m-md') {
      bottom: 10%;
      img {
        max-width: 50px !important;
      }
      left: 35%;
    }
  }
}
