.comparison-list {
  align-items: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  &__table {
    font-family: $font--geometos;
    img {
      display: block;
      width: 75%;
      margin: 0 auto;
      @include bp('m-md') {
        width: 50%;
      }
      @include bp('m-lg') {
        width: auto;
      }
    }
    li {
      margin: 0 0.9375rem;
      text-align: center;
      &:nth-child(1) {
        margin-top: 1.125rem;
        @include bp('m-md') {
          margin-top: 3.125rem;
        }
      }
      &:nth-child(2) {
      }
      &:nth-child(3) {
        font-size: 1.25rem;
        span {
          display: block;
          font-size: 2.5rem;
        }
      }
    }
  }
}
