$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: '../fonts/icomoon' !default;
$icon--chevron: '\e900';
$icon--cross: '\e901';
$icon--location: '\e902';
$icon--logo-facebook: '\e903';
$icon--logo-instagram: '\e904';
$icon--logo-twitter: '\e905';
$icon--menu: '\e906';
$icon--play: '\e907';
$icon--repeat: '\e908';
$icon--speaker: '\e90a';
$icon--tick: '\e909';

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  &--within-button-left {
    margin: 0 0.625rem 0 0;
  }
  &--within-button-right {
    margin: 0 0 0 0.625rem;
  }
  &--hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.icon--speaker {
  &:before {
    content: $icon--speaker;
    color: #fff;
  }
}
.icon--chevron {
  &:before {
    content: $icon--chevron;
    color: #fff;
  }
}
.icon--cross {
  &:before {
    content: $icon--cross;
    color: #fff;
  }
}
.icon--location {
  &:before {
    content: $icon--location;
    color: #fff;
  }
}
.icon--logo-facebook {
  &:before {
    content: $icon--logo-facebook;
    color: #fff;
  }
}
.icon--logo-instagram {
  &:before {
    content: $icon--logo-instagram;
    color: #fff;
  }
}
.icon--logo-twitter {
  &:before {
    content: $icon--logo-twitter;
    color: #fff;
  }
}
.icon--menu {
  &:before {
    content: $icon--menu;
    color: #fff;
  }
}
.icon--play {
  &:before {
    content: $icon--play;
    color: #fff;
  }
}
.icon--repeat {
  &:before {
    content: $icon--repeat;
    color: #fff;
  }
}
.icon--tick {
  &:before {
    content: $icon--tick;
    color: #fff;
  }
}
