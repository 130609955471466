.pledge {
  background-color: $color--white;
  border-radius: 20px;
  padding: 40px;

  &__counter {
    color: $color--green-dark;
    @include fluid-type(16px, 20px);
    font-weight: 400;
    font-family: $font--geometos;
    padding-bottom: 20px;

    span {
      @include fluid-type(32px, 36px);
    }
  }

  &__promise {
    color: $color--green-dark;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
  }

  &__footer {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 1rem 0 0 0;
      align-items: center;
      li {
        position: relative;
        margin: 0 0.3125rem 0 0;
        &:last-of-type {
          &:after {
            display: none;
          }
        }
        &:after {
          display: block;
          color: $color--green-dark;
          content: '/';
          position: absolute;
          right: -0.3125rem;
          top: 0;
        }
        a {
          color: $color--green-dark;
          padding: 0.5rem;
          font-weight: 500;
          text-decoration: none;
          @include bp('m-lg') {
          }
          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &__name {
    border: none;
    border-bottom: $color--green-dark 2px solid;
    // margin: 0 0.3125rem;
    width: 90%;
    // padding: 0.375rem;
    display: inline-block;
    font-size: 24px;
    outline: none;
  }
  &__error {
    display: block;
    color: red;
    flex: 1 0 100%;
    margin: 0 0 0 2.0625rem;
  }
  // &__content {
  //   // margin: 0 0 0.3125rem 0;
  //   text-align: left;

  //   @include bp('-m-md') {
  //     font-size: 0.875rem;
  //     line-height: 0.875rem;
  //   }
  // }
  &__checkbox-group {
    display: flex;
    padding: 0;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0.625rem 0 0 0;
    @include bp('m-md') {
      margin: 1rem 0 0 0;
    }
    &:last-of-type {
      margin: 0.625rem 0;
      @include bp('m-md') {
        margin: 1rem 0;
      }
    }
  }
  &__checkbox {
    appearance: none;
    background-color: $color--white;
    border-radius: 0;
    border: 0.0625rem solid $color--grey-dark;
    display: inline-block;
    margin: 0 0.75rem -0.2125rem 0;
    padding: 0.5625rem;
    position: relative;
    &:active:checked,
    &:active {
      background-color: darken($color--green-dark, 5);
    }
    &:checked {
      background-color: $color--green-dark;
      border: 0.0625rem solid transparent;
      color: $color--white;
      font-family: '#{$icomoon-font-family}' !important;
      &:after {
        color: $color--white;
        content: $icon--tick;
        font-size: 0.875rem;
        left: 0.125rem;
        position: absolute;
        top: 0.0625rem;
      }
    }
  }
  &__checkbox-label {
    flex: 1;
    @include bp('-m-md') {
      font-size: 0.875rem;
      line-height: 0.875rem;
    }
  }
  &__step {
    &--closed {
      display: none;
    }
  }
  &__step-1 {
  }
  &__step-2 {
  }
  &__social-links-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5625rem 0;
    text-align: center;
    li {
      display: block;
      margin: 0 1.25rem;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0.625rem;
      background-color: $color--green-dark;
      border-radius: 3.125rem;
      text-decoration: none;
      font-size: 0rem;
      padding: 1.125rem 0.875rem 0.625rem 0.875rem;
      i {
        line-height: 0.75rem;
        &:before {
          font-size: 1.375rem;
        }
      }
    }
  }
}
